//
// bootstrap-timepicker.scss
//
.bootstrap-timepicker-widget {
  table {
      td {
          input {
              height: 32px;
              width: 32px;
              color: $white;
              background-color: $primary;
              border-radius: 0%;
              border: 0;
              outline: none !important;
          }
          a {
              color: #{map-get($grays, "700")};
              &:hover {
                  background-color: transparent;
                  border: 1px solid transparent;
                  color: $primary;
              }
          }
      }
  }
  &.dropdown-menu {
    &:before {
        border-bottom: 7px solid $dropdown-border-color;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
    }
    &:after {
        border-bottom: 6px solid $dropdown-bg;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
    }
  }
  &.timepicker-orient-bottom {
      &:after {
          bottom: -6px;
          border-bottom: 0;
          border-top: 6px solid $dropdown-bg;
      }
      &:before {
          bottom: -7px;
          border-bottom: 0;
          border-top: 7px solid $dropdown-border-color;
      }
  }
}



// Dark mode
body[data-layout-color="dark"] {
    .bootstrap-timepicker-widget {
        table {
            td {
                a {
                    color: #{map-get($dark-grays, "700")};
                }
            }
        }
    }
}